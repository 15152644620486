<template>
  <div :class="step == 1 ? 'log-off-container1' : 'log-off-container'">
    <div
      :style="explorerChromeSafari ? 'height: calc(100vh - 19rem) !important;overflow-y: auto;' : 'height: calc(100vh - 13rem) !important;overflow-y: auto;'"
      v-if="step == 1"
    >
      <!-- 当前账户信息 -->
      <div class="log-off-account-info col-start">
        <p
          class="log-off-title"
          style="margin-bottom: 8px"
        >当前账户</p>
        <p class="log-off-tip">昵称：{{ accountInfo.userInfo.nickname }}</p>
        <p class="log-off-tip">UID：{{ accountInfo.userInfo.uid }}</p>
        <p class="log-off-tip">手机号：{{ accountInfo.userInfo.phone }}</p>
      </div>
      <!-- 注销账户条件 -->
      <div class="log-off-account-condition col-start">
        <p
          class="log-off-title row-center"
          style="margin-bottom: 8px"
        >
          注销账户需同时满足以下条件：
          <img
            @click="logOffVisible = true"
            class="log-off-icon"
            src="../assets/icon/logOff/question.png"
          />
        </p>
        <p class="log-off-tip">1.账号处于正常状态</p>
        <p class="log-off-tip-item row-center">
          {{accountInfo.condition.userStatus ? '账号状态正常' : '账号状态异常'}}
          <img
            class="log-off-icon"
            :src="accountInfo.condition.userStatus ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
        <p
          style="margin-top:16px;"
          class="log-off-tip"
        >2.账户藏品已清空</p>
        <p class="log-off-tip-item row-center">
          {{accountInfo.condition.assetsIsEmpty ? '藏品未清空' : '藏品已清空'}}
          <img
            class="log-off-icon"
            :src="accountInfo.condition.assetsIsEmpty ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
        <p
          style="margin-top:16px;"
          class="log-off-tip"
        >3.账户所有订单处于“已完成”状态</p>
        <p class="log-off-tip-item row-center">
          购买订单
          <img
            class="log-off-icon"
            :src="accountInfo.condition.buyOrder ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
        <p class="log-off-tip-item row-center">
          转赠订单
          <img
            class="log-off-icon"
            :src="accountInfo.condition.giftOrder ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
        <p class="log-off-tip-item row-center">
          合成订单
          <img
            class="log-off-icon"
            :src="accountInfo.condition.mergeOrder ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
        <p class="log-off-tip-item row-center">
          盲盒订单
          <img
            class="log-off-icon"
            :src="accountInfo.condition.boxOrder ? require('../assets/icon/logOff/success.png') : require('../assets/icon/logOff/error.png')"
          />
        </p>
      </div>
      <!-- 注销说明 -->
      <div
        class="log-off-account-tip col-start"
        style="margin-bottom: 6rem;"
      >
        <p
          class="log-off-title"
          style="margin-bottom: 8px"
        >注销说明</p>
        <p class="log-off-tip2">①注销前请仔细阅读《Meta彼岸账号注销协议》，再进行操作。</p>
        <p class="log-off-tip2">②申请注销账户之日起，7天内再次登录账户，则账户恢复正常。</p>
        <p class="log-off-tip2">③申请注销账户之日起，30天后系统将删除账户UID、手机号、实名信息、邀请信息等，账户中的积分将清零，好友关系将被解除，注销后不可恢复。</p>
        <p class="log-off-tip2">④账户注销后，180内不能再次注册。</p>
      </div>

    </div>
    <!-- 注销协议 -->
    <div
      v-if="step == 1"
      class="log-off-user-tip-checkout"
    >
      <div
        style="margin-top: 26px;"
        class="row-center "
      >
        <el-checkbox v-model="isLogOff"></el-checkbox>
        <p class="log-off-user-tip">阅读并同意<a @click="goLogOffPolicy">《Meta彼岸账号注销协议》</a></p>
      </div>
      <!-- 注销按钮显示 -->
      <div
        class="row-center"
        @click="logOff"
        :class="isShowButtom ? 'buy-now' : 'sold-out'"
      >申请注销</div>
    </div>

    <div
      v-if="step == 2"
      class="certification-main"
    >
      <van-form @submit="onSubmit">
        <p class="modify-phone-num">账户: {{  accountInfo.userInfo.phone  }}</p>
        <div
          v-if="isCaptcha1"
          @click="getSms"
          style="margin-left: 5%;margin-bottom: 16px;"
          class="right-slider-black-container"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider1"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>
        <div class="cer-sms-container">
          <van-field
            autocomplete="off"
            v-model="sms"
            ref="sms"
            class="login-sms"
            type="digit"
            maxlength="6"
            name="validatorSms"
            label="短信验证码"
            :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="disabled"
            @click="getSms"
          >
            {{ text }}
          </button>
        </div>
        <div style="margin: 2.75rem 1.125rem 1rem 1.375rem;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >确 定</van-button>
        </div>
      </van-form>

    </div>

    <!-- 注销提示 -->
    <el-dialog
      :visible.sync="logOffVisible"
      @confirm="logOffVisible = false"
      class="log-off-dialog"
      center
    >
      <p class="log-off-tip2">1.账号正常指该账号可以正常登录使用，并且无任何违规记录等</p>
      <p class="log-off-tip2">2.所有订单包括但不限于购买订单、转赠订单、合成订单，可在“我的-订单”中查看并处理未完成订单</p>
      <p class="log-off-tip2">3.清空藏品的方式可选择转赠他人或者转赠至官方销毁账户，UID：666</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="logOffVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      title="确认注销此账户吗？"
      :visible.sync="logOffConfirm"
      @confirm="$router.push('/certification')"
      class="log-off-confirm-dialog"
      center
    >
      <p
        style="margin-top:10px;"
        class="log-off-tip3"
      >昵称：{{ accountInfo.userInfo.nickname }}</p>
      <p class="log-off-tip3">UID：{{ accountInfo.userInfo.uid }}</p>
      <p class="log-off-tip3">手机号：{{ accountInfo.userInfo.phone }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="logOffConfirm = false">取 消</el-button>
        <div
          @click="logOffConfirm = false;step=2"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue';
import { create } from '../lib/captcha.js'
import md5 from 'js-md5';
import Cookies from 'js-cookie'
const config = require('../config')
import { captchaAppKey } from '../lib/index.js'
import { Checkbox,Dialog } from 'element-ui';
import { Form,Field,Button } from 'vant';
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
export default {
  data () {
    return {
      explorerChromeSafari: false,
      disabled: false,
      text: '获取验证码',
      isCaptcha1: false,
      sms: '',
      step: 1,
      logOffConfirm: false,
      logOffVisible: false,
      isLogOff: false,
      accountInfo: {
        userInfo: {
          nickname: '',
          phone: '',
          uid: ''
        },
        condition: {
          assetsIsEmpty: false,
          buyOrder: false,
          giftOrder: false,
          mergeOrder: false,
          userStatus: false,
          boxOrder: false,
        }
      }
    }
  },
  computed: {
    isShowButtom () {
      return this.isLogOff &&
        this.accountInfo.condition.assetsIsEmpty &&
        this.accountInfo.condition.buyOrder &&
        this.accountInfo.condition.giftOrder &&
        this.accountInfo.condition.mergeOrder &&
        this.accountInfo.condition.userStatus &&
        this.accountInfo.condition.boxOrder
    }
  },
  mounted () {
    // 查询注销账户条件
    this.getCancelCondition()
    this.checkUserAgent()
  },
  methods: {
    // 检查设备，适配
    checkUserAgent () {
      var explorer = navigator.userAgent

      if (/(micromessenger)/i.test(explorer)) {
        // 微信浏览器
        this.explorerChromeSafari = false
      } else if (/(MQQBrowser)/i.test(navigator.userAgent)) {
        // qq
        this.explorerChromeSafari = true
      } else if (/(?:Chrome|CriOS)/.test(explorer)) {
        // 谷歌
        this.explorerChromeSafari = true
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(explorer)) {
        // 除上面3个浏览器外的ios设备
        this.explorerChromeSafari = true
      } else {
        // 其他设备
        this.explorerChromeSafari = false
      }
    },
    getCancelCondition () {
      api
        .get('user/getCancelCondition')
        .then(result => {
          if (result.data.success) {

            this.accountInfo = result.data.data
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 校验验证码
    validatorSms (val) {
      return /^\d{6}$/.test(val);
    },
    // 验证手机号
    onSubmit (values) {
      api
        .post('verify',{ "mobile": this.accountInfo.userInfo.phone,"code": this.sms,from: 'h5',deviceNo: 'h5' })
        .then(result => {

          if (result.data.success && result.data.data) {
            this.cancelAccount()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 注销账户接口
    cancelAccount () {
      this.$store.commit('SHOW_APPLOADING')
      api
        .post('user/cancelAccount')
        .then(result => {
          if (result.data.success) {
            this.$toast({
              message: '注销成功',
              icon: require('../assets/icon/toast-success.png'),
            });
            if (this.$route.query.from == "h5") {
              Cookies.remove('Login')
              this.$router.replace('/login')
            } else if (this.$route.query.from == "ios") {
              window.webkit.messageHandlers.logout.postMessage('');
            } else if (this.$route.query.from == "android") {
              appBridge.logout()
            }

          } else {
            this.step = 1
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取验证码
    getSms () {
      this.isCaptcha1 = true
      this.$nextTick(() => {
        this.captcha = create('login-main-right-slider1',data => {
          var timestamp = new Date().getTime()
          var nonce = md5(String(timestamp))
          var signStr = 'appId=' + config.appId + '&mobile=' + this.accountInfo.userInfo.phone + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
          var sign = md5(signStr)
          api
            .post('afs/sms',{ phone: this.accountInfo.userInfo.phone,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
            .then(result => {
              if (result.data.success) {
                this.time = 60;
                this.timer();
                this.$toast({
                  message: '获取成功',
                  icon: require('../assets/icon/toast-success.png'),
                });
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
              this.isCaptcha1 = false
            })
        })
        this.captcha.reset()
        this.captcha.show()
      })
    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "获取验证码";
        this.disabled = false;
      }
    },
    // 注销协议
    goLogOffPolicy () {
      this.$router.push('/logOffPolicy?from=h5')
    },
    // 确认信息
    logOff () {
      if (this.isShowButtom) {
        this.logOffConfirm = true
      }
    }
  },
}
</script>
<style lang="less" scoped>
.log-off-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
}
.log-off-container1 {
  margin-top: 3.875rem;
  position: relative;
  height: calc(100vh - 3.875rem);
  min-height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
}
.log-off-title {
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #fefefe;
  margin-left: 17px;
}
.log-off-tip {
  font-size: 13px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cccccc;
  margin-left: 17px;
}
.log-off-tip2 {
  font-size: 13px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cccccc;
  margin-left: 17px;
  margin-bottom: 8px;
}
.log-off-tip3 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 19px;
  width: 80%;
  margin-left: 10%;
}
.log-off-tip-item {
  font-size: 12px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #a3a3a3;
  margin-left: 17px;
}
.log-off-icon {
  width: 13.5px;
  height: auto;
  margin-left: 3px;
}
.log-off-account-info {
  margin-top: 1rem;
  width: 90vw;
  margin-left: 5vw;
  height: 100px;
  background: #262728;
  border-radius: 8px;
}
.log-off-account-condition {
  margin-top: 10.5px;
  width: 90vw;
  margin-left: 5vw;
  height: 240.5px;
  background: #262728;
  border-radius: 8px;
}
.log-off-account-tip {
  margin-top: 10.5px;
  width: 90vw;
  margin-left: 5vw;
  height: 220px;
  background: #262728;
  border-radius: 8px;
}
.log-off-user-tip {
  margin-left: 11px;
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #fefefe;
  a {
    font-size: 14px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #7ed0ff;
  }
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ffffff;
  border-color: #ffffff;
}
.buy-now {
  width: 90vw;
  height: 46px;
  margin: 1.3125rem 5vw 1.4375rem 5vw;
  background-image: url('../assets/icon/confirm-button.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-color: transparent;
  font-size: 1rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.375rem;
  letter-spacing: 2px;
  cursor: pointer;
  background-size: cover;
}

.sold-out {
  width: 90vw;
  height: 46px;
  margin: 1.3125rem 5vw 1.4375rem 5vw;
  background-image: url('../assets/icon/sold-out.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-color: transparent;
  font-size: 1rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.375rem;
  cursor: not-allowed;
  pointer-events: none;
  background-size: cover;
}

/deep/.el-checkbox__inner:hover {
  border-color: #000;
}
/deep/.el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #000;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
/deep/.el-dialog__body {
  width: 96%;
}
/deep/.log-off-dialog > .el-dialog {
  height: 250px;
}
.certification-main {
  .van-form {
    .van-cell::after {
      display: none;
    }

    .van-cell {
      background: none;
      padding: 1rem 0.8125rem;

      .van-cell__title,
      .van-cell__value {
        font-size: 0.875rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #b3b3b3 !important;
        line-height: 1.1875rem;
      }

      .van-field__error-message {
        position: absolute;
        top: 1rem;
      }
    }

    .van-button {
      height: 2.875rem;
      position: relative;
      background-image: url('../assets/icon/confirm-button.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: 100% auto;
      background-color: transparent;
      font-size: 1rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      border-radius: 0;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
.modify-phone-num {
  text-align: left;
  width: 90vw;
  margin: 1.0625rem 5vw 20px 5vw;
  font-size: 1.125rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 2.125rem;
  letter-spacing: 0.0625rem;
}
.certification-main {
  .certification-main-title {
    text-align: center;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #cccccc;
    line-height: 19px;
    margin-bottom: 1.0625rem;
  }
}
.cer-sms-container {
  width: 90%;
  margin-left: 5%;
  height: 3.25rem;
  background: #313131 !important;
  border-radius: 0.25rem;
  position: relative;
  .van-cell__value {
    input {
      width: 6.25rem;
    }
  }
  button {
    position: absolute;
    bottom: 1rem;
    right: 0.625rem;
    background: none;
    border: none;
    outline: none;
    font-size: 0.875rem;
    font-family: lantinghei;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.1875rem;
  }
}
/deep/.van-field__control {
  font-size: 0.875rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff !important;
  line-height: 1.1875rem;
}
.log-off-user-tip-checkout {
  // position: absolute;
  width: 100vw;
  // bottom: 0;
  background: #1c1c1c;
  z-index: 20;
}
</style>